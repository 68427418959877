import React, { useContext, useEffect, useState } from 'react'
import FullCalendar from "@fullcalendar/react";
import { EventApi, EventDropArg, DateSpanApi } from "@fullcalendar/core"
import { EventReceiveArg } from "@fullcalendar/interaction"

import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import rrulePlugin from '@fullcalendar/rrule'
import { add, format, startOfWeek, sub } from 'date-fns'
import './assets/scheduler.scss'
import { resourcesContext } from "../../providers/resources";
import interactionPlugin from "@fullcalendar/interaction"
import { EventRender, OtherSiteEventRender } from "./Events/EventRender";
import { DraftStatus, shiftsContext } from "../../providers/shifts";
import { Loading } from "../Loading";
import { Columns } from "./Columns/Columns";
import { eventAllowed, eventDragStop } from "../../helpers/calendar";
import { Header } from "./Header/Header";
import Legend from './Legend/Legend';
import { renderGenericFallbackUi } from '../error-boundary/generic-fallback-ui';
import { IEvent } from "../../interfaces/event";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from '../../applicationInsights';
import Swal from 'sweetalert2';
import { toast } from '../../helpers/notifications';


export type TView = "resourceTimelineWeek" | "resourceTimelineFortnight"

const Scheduler = ({ openModal }: { openModal: (id: string) => void }) => {
    const { getAllResources, getIlluminaAvailablities } = useContext(resourcesContext)
    const {
        moveShift,
        getShiftsForCalendarView,
        getOtherSiteShiftsForCalendarView,
        loader,
        checkShiftLocationIsValid,
        setCalendarDates,
        getShift,
        checkIsModified,
        draftStatus,
        getResourceData,
        checkRequiredCharacteristics,
        defineUserAvailability,
        checkIfShiftAllowed,
        startDate,
        endDate,
        showOtherSiteShifts,
        toggleShowOtherSiteShifts,
        loadDraft,
        view,
        setView,
        loadingPayrollData,
        isCancellationReviewPending,
        validated
    } = useContext(shiftsContext)

    const [loadingBidders, setLoadingBidders] = useState(false)


    useEffect(() => {
        if (draftStatus === DraftStatus.found && validated) {
            handleShowLoadDraftPrompt()
        }
    }, [draftStatus, validated])

    const handleShowLoadDraftPrompt = async () => {
        const result = await Swal.fire({
            title: 'Load Draft?',
            text: 'Do you want to load the saved draft?',
            icon: 'info',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            confirmButtonColor: 'green',
            denyButtonColor: 'red',
        })

        if (result.isConfirmed) {
            loadDraft();
        }
    }


    const calendarRef = React.createRef<FullCalendar>()

    const views = {
        resourceTimelineWeek: {
            type: 'resourceTimeline',
            buttonText: 'Week',
            slotDuration: { day: 1 },
            duration: { days: 7 },
        },
        resourceTimelineFortnight: {
            type: 'resourceTimeline',
            slotDuration: { day: 1 },
            buttonText: 'Fortnight',
            duration: { days: 14 },
        }
    }

    const setCalendarDatesWithView = (date: Date) => {
        if (loadingPayrollData) return;
        setCalendarDates(date, view)
    }

    const updateDateRange = (dates: Date[]) => {
        if (loadingPayrollData) return;
        if (!dates || dates.length < 2) return;

        let startDate = startOfWeek(dates[0], { weekStartsOn: 1 });
        setCalendarDatesWithView(startDate)

        calendarRef.current?.getApi().gotoDate(startDate)
        // @ts-ignore

    }

    const increaseDate = () => {
        const nDate = add(startDate, {
            weeks: view === "resourceTimelineWeek" ? 1 : 2
        })
        setCalendarDatesWithView(nDate)
        calendarRef.current?.getApi().gotoDate(nDate)

    }

    const decreaseDate = () => {
        const nDate = sub(startDate, {
            weeks: view === "resourceTimelineWeek" ? 1 : 2
        })
        setCalendarDatesWithView(nDate)
        calendarRef.current?.getApi().gotoDate(nDate)
    }

    const setToCurrentWeek = () => {
        const nDate = startOfWeek(new Date(), { weekStartsOn: 1 })
        calendarRef.current?.getApi().gotoDate(nDate)
        setCalendarDatesWithView(nDate)
    }

    const updateView = (v: TView) => {
        setCalendarDates(startDate, v)
        calendarRef.current?.getApi().changeView(v)
        setView(v)
    }

    const eventClick = async (event: EventApi) => {
        if (event.display == 'background') return;

        if (!event.extendedProps.otherSite) {
            const eventRequested = getShift(event.id);
            if (eventRequested?.bidders?.length == 0) {
                openModal(event.id);
                return;
            }

            setLoadingBidders(true);

            await checkIfShiftAllowed(eventRequested as IEvent);

            openModal(event.id);
            setLoadingBidders(false);
        }
    }

    const openNewJobModal = () => {
        window.top?.postMessage({ type: 'ac_rb_new_job' }, '*');
    }


    if (loader?.loading) {
        return <Loading label={loader.message} />
    }

    if (draftStatus === DraftStatus.publishing) {
        return <Loading label={"Publishing..."} />
    }

    async function handleEventDrop(info: EventDropArg) {
        if (info.event.extendedProps?.loading || info.event.extendedProps?.validating) {
            return
        }
        await moveShift(info.event.id, info.newResource?.id)
    }

    async function handleEventReceive(info: EventReceiveArg) {
        if (info.event.extendedProps?.loading || info.event.extendedProps?.validating) {
            return
        }
        const resources = info.event.getResources()
        if (Array.isArray(resources) && resources.length) {
            await moveShift(info.event.id, resources[0].id)
        }
    }

    function handleEventAllow(span: DateSpanApi, event: EventApi | null): boolean {
        if (event?.extendedProps.loading || event?.extendedProps?.validating) {
            return false
        }
        return eventAllowed(span, checkShiftLocationIsValid, calendarRef, event, defineUserAvailability)
    }

    return (
        <div>
            {loadingBidders &&
                <div className='loading style-2'>
                    <div className='loading-wheel' />
                    <div className='loading-text'>Loading...</div>
                </div>
            }

            <div style={{ boxSizing: 'border-box', display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <Header
                    increaseDate={increaseDate}
                    decreaseDate={decreaseDate}
                    setToCurrentWeek={setToCurrentWeek}
                    date={startDate}
                    updateDateRange={updateDateRange}
                    dateRange={[startDate, endDate]}
                    updateView={updateView}
                    view={view}
                    setDate={setCalendarDatesWithView}
                    showOtherSiteShifts={showOtherSiteShifts}
                    toggleShowOtherSitesShifts={toggleShowOtherSiteShifts}
                    loadingPayrollData={loadingPayrollData}
                    openNewJobModal={openNewJobModal}
                />
                <div style={{ display: 'flex', flex: 1 }} id={"calender"}>
                    <div style={{ flex: 6 }}>
                        <AppInsightsErrorBoundary
                            onError={() =>
                                renderGenericFallbackUi({
                                    mainHeading: 'Something went wrong whilst trying to load the scheduler',
                                    subHeading: 'Please try again in a few minutes'
                                })
                            }
                            appInsights={reactPlugin}
                        >
                            <FullCalendar
                                ref={calendarRef}
                                resourceAreaColumns={Columns()}
                                plugins={[resourceTimelinePlugin, interactionPlugin,rrulePlugin]}
                                headerToolbar={false}
                                slotMinWidth={180}
                                height={'100%'}
                                initialDate={startDate}
                                slotLabelContent={(e) => {
                                    const date = new Date(e.date)
                                    return (<>{format(date, 'EEEE do')}</>)
                                }}
                                slotLabelFormat={{ weekday: 'long', day: 'numeric' }}
                                schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
                                views={views}
                                // datesSet={(dateRange) => {
                                //     setCalendarDates(dateRange.start, view)
                                // }}
                                initialView={view}
                                resources={getAllResources() ?? []}
                                events={[
                                    ...getShiftsForCalendarView(),
                                    ...getIlluminaAvailablities(),
                                    ...(showOtherSiteShifts ? getOtherSiteShiftsForCalendarView() : []),
                                    {
                                        id: 'marker',
                                        start: 0,
                                        display: 'background'
                                    }
                                ]}
                                eventContent={({ event, isDragging }) => {
                                    if (event.extendedProps.otherSite) {
                                        return (<OtherSiteEventRender event={event} />)
                                    } else {
                                        return (EventRender(event, getShift, isDragging, checkRequiredCharacteristics, getResourceData, checkIsModified, eventClick, isCancellationReviewPending))
                                    }
                                }}
                                eventColor={'transparent'}
                                eventTextColor={'black'}
                                editable={false}
                                eventDurationEditable={false}
                                eventResourceEditable={true}
                                resourceOrder={'index'}
                                resourceAreaWidth={350}
                                eventOverlap={true}
                                eventDragStop={() => eventDragStop(calendarRef)}
                                eventAllow={handleEventAllow}
                                eventDrop={handleEventDrop}
                                eventReceive={handleEventReceive}
                            />
                        </AppInsightsErrorBoundary>
                    </div>
                </div>

                <Legend />
            </div>
        </div>
    )
}


export { Scheduler }




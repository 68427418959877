import { EventSourceInput } from "@fullcalendar/core";
import {
    ChangeStatus,
    EBookingStatus,
    ECalendarStatus,
    ESystemStatus,
    IBookableResourceBooking,
    IEvent,
    IOtherSiteEvent
} from "../../interfaces/event";
import { IIncidentType } from "../../interfaces/incidentTypes";
import { DayOfWeek, IBookableresourceAvailability, IOtherSiteWorkOrder, IResource, ITimeOffRequest, IWorkOrder, IWorkOrderType, RepeatPattern, ShiftCancellationRequestStatus } from "../../interfaces/resource";
import addMinutes from "date-fns/addMinutes";
import { addDays, differenceInMinutes, endOfDay, intervalToDuration, isSameDay, isSameMinute, startOfDay } from "date-fns";
import { Frequency, RRule } from "rrule";

const shiftsFormatter = (apiShift: IWorkOrder, shiftTypes: IWorkOrderType[], incidentTypes: IIncidentType[]): IEvent | undefined => {
    if (apiShift.msdyn_systemstatus === ESystemStatus.Canceled) {
        return;
    }

    const bidders = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() == 'expressed interest'); //Expressed interest is the display name - id can't be used as it might change in prod
    const assignedWorkBookings = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'expressed interest'
        && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'canceled' && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'coordinator canceled');
    const event: Partial<IEvent> = {}

    if (assignedWorkBookings.length > 0) {
        const assignedBooking = assignedWorkBookings[0];
        event.resourceId = assignedBooking['_resource_value']
        event.originalResourceID = assignedBooking['_resource_value']
        event.bookableResourceStatus = assignedBooking['_bookingstatus_value'] as any;
        event.BookingId = assignedBooking.bookableresourcebookingid;
        event.isCancellationRequestPendingOriginal = assignedBooking.illumina_cancellationrequeststatus == ShiftCancellationRequestStatus.Pending;
        event.cancellationRequestComments = assignedBooking.illumina_cancellationrequestcomments;

    } else {
        event.resourceId = '0'
        event.originalResourceID = '0'
        event.bookableResourceStatus = EBookingStatus.Scheduled
        event.BookingId = '';
    }


    const primaryIncidentType = () => {
        if (apiShift.msdyn_primaryincidenttype && apiShift.msdyn_primaryincidenttype.msdyn_incidenttypeid) {
            return incidentTypes.find(type => {
                return type.id === apiShift.msdyn_primaryincidenttype.msdyn_incidenttypeid
            })
        }
        return {
            name: 'Not Required',
            id: '0',
            characteristic: []
        }
    }
    const sd = new Date(apiShift.msdyn_timewindowstart);
    const ed = apiShift.msdyn_totalestimatedduration ? addMinutes(sd, apiShift.msdyn_totalestimatedduration) : new Date(apiShift.msdyn_timewindowend); // if duration exists, use duration. Otherwise use enddate
    const shiftDuration = apiShift.msdyn_totalestimatedduration ? apiShift.msdyn_totalestimatedduration : differenceInMinutes(ed, sd)
    return {
        title: apiShift.msdyn_name,
        start: sd,
        end: ed,
        id: apiShift.msdyn_workorderid,
        description: apiShift.msdyn_workordersummary ? apiShift.msdyn_workordersummary : apiShift.msdyn_name,
        status: apiShift.msdyn_systemstatus,
        preferredResourceId: apiShift._msdyn_preferredresource_value,
        preferredResourceName: apiShift['_msdyn_preferredresource_value@OData.Community.Display.V1.FormattedValue'],
        jobType: apiShift['illumina_jobtype@OData.Community.Display.V1.FormattedValue'],
        displayColor: shiftTypes.find(x => x.msdyn_workordertypeid == apiShift._msdyn_workordertype_value)?.illumina_displaycolour,
        incidentType: primaryIncidentType(),
        bidders: bidders.map(x => <IBookableResourceBooking>{
            bookingStatus: x._bookingstatus_value,
            id: x.bookableresourcebookingid,
            bookableResourceId: x._resource_value
        }),
        calendarStatus: (event.resourceId === '0') ? ECalendarStatus.scheduled : ECalendarStatus.assigned,
        serviceAccount: {
            name: apiShift.msdyn_serviceaccount.name,
            accountId: apiShift.msdyn_serviceaccount.accountid,
            accountNumber: apiShift.msdyn_serviceaccount.accountnumber
        },
        workOrderType: {
            msdyn_workordertypeid: apiShift.msdyn_workordertype?.msdyn_workordertypeid,
            msdyn_name: apiShift.msdyn_workordertype?.msdyn_name,
            illumina_displaycolour: apiShift.msdyn_workordertype?.illumina_displaycolour
        },
        isJobBidding: apiShift.illumina_openforshiftjobbidding,
        changeStatus: ChangeStatus.UnChanged,
        shiftLength: shiftDuration,
        loading: false,
        ...event
    } as IEvent
}

const leaveFormatter = (leave: ITimeOffRequest, resource: IResource): IEvent => {
    return {
        title: "On Leave",
        start: leave.start,
        end: leave.end,
        id: leave.id,
        description: '',
        resourceId: resource.id,
        originalResourceID: resource.id,
        changeStatus: ChangeStatus.UnChanged,
        loading: false,
        editable: false,
        display: 'unavailable',
        resourceEditable: false
    } as IEvent
}

const otherSiteShiftFormatter = (apiShift: IOtherSiteWorkOrder, shiftTypes: IWorkOrderType[]): IOtherSiteEvent | undefined => {
    const assignedWorkBookings = apiShift.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder.filter(x => x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'expressed interest'
        && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"] != 'Canceled' && x["_bookingstatus_value@OData.Community.Display.V1.FormattedValue"]?.toLocaleLowerCase() != 'coordinator canceled');

    const sd = new Date(apiShift.msdyn_timefrompromised);
    const ed = apiShift.msdyn_totalestimatedduration
        ? addMinutes(sd, apiShift.msdyn_totalestimatedduration)
        : new Date(apiShift.msdyn_timewindowend);
    const shiftDuration = apiShift.msdyn_totalestimatedduration ? apiShift.msdyn_totalestimatedduration : differenceInMinutes(ed, sd)

    if (assignedWorkBookings.length > 0) {
        const event: IOtherSiteEvent = {
            id: apiShift.msdyn_workorderid,
            editable: false,
            resourceEditable: false,
            startEditable: false,
            otherSite: true,
            resourceId: assignedWorkBookings[0]._resource_value,
            title: apiShift.msdyn_name,
            start: sd,
            end: ed,
            shiftLength: shiftDuration,
            displayColor: shiftTypes.find(x => x.msdyn_workordertypeid == apiShift._msdyn_workordertype_value)?.illumina_displaycolour,
            jobType: apiShift.illumina_jobtype,
            customerFullName: apiShift.illumina_ServiceContact?.fullname || "Customer Missing",
            siteLocation: apiShift["_illumina_sitelocationfromid_value@OData.Community.Display.V1.FormattedValue"] || "Site Location missing",
            workOrderType: {
                msdyn_workordertypeid: apiShift.msdyn_workordertype?.msdyn_workordertypeid,
                msdyn_name: apiShift.msdyn_workordertype?.msdyn_name,
                illumina_displaycolour: apiShift.msdyn_workordertype?.illumina_displaycolour
            }

        }

        return event;
    }


}

const availabilityFormatter = (resourceId: string, availability: IBookableresourceAvailability): IEvent => {
    let start = new Date(availability.illumina_startdate);
    let end = new Date(availability.illumina_enddate);
    let shiftLength = availability.illumina_allday ? 24 * 60 : differenceInMinutes(new Date(availability.illumina_enddate), new Date(availability.illumina_startdate));


    let event = {
        resourceId: resourceId,
        title: `${availability["illumina_availabilitytype@OData.Community.Display.V1.FormattedValue"]}`,
        id: availability.illumina_availabilityid,
        display: 'background',
        shiftLength: shiftLength,
        description: '',
        editable: false,
        availabilityType: availability.illumina_availabilitytype,
        resourceEditable: false
    } as IEvent;

    if (isSameDay(start, end)) {
        event.start = start;
        event.allDay = availability.illumina_allday;
        event.end = end
        return event;
    }

    if (!availability.illumina_repeatpattern) {
        event.start = start;
        event.allDay = availability.illumina_allday;
        event.end = availability.illumina_allday ? endOfDay(end) : end;
        return event;
    }


    if (availability.illumina_allday) {
        start = startOfDay(start);
        end = endOfDay(end);
        event.allDay = true;
    }
    else {
        let startDateTime = start;
        let endDateTime = new Date(start.getFullYear(), start.getMonth(), start.getDate(), end.getHours(), end.getMinutes());
        if (endDateTime < startDateTime) //if time span over multilpe days, for example 1st Jan 24 11PM to 2nd Jan 24 2AM
            endDateTime = addDays(endDateTime, 1);

        let diffInTotalMins = differenceInMinutes(endDateTime, startDateTime);
        let diffInHours = Math.trunc(diffInTotalMins / 60);
        let diffInMins = diffInTotalMins - (diffInHours * 60);
        event.duration = `${diffInHours.toString().padStart(2, '0')}:${diffInMins.toString().padStart(2, '0')}`;
    }
    let until = end;

    switch (availability.illumina_repeatpattern) {
        case RepeatPattern.Daily:
            event.rrule = {
                freq: Frequency.DAILY,
                interval: availability.illumina_repeatoccurence,
                dtstart: start.toISOString(),
                until: until.toISOString()
            };
            break;
        case RepeatPattern.Weekly:
            let days = availability.illumina_days.split(',').map(d => {
                switch (parseInt(d)) {
                    case DayOfWeek.Friday:
                        return RRule.FR;
                    case DayOfWeek.Monday:
                        return RRule.MO;
                    case DayOfWeek.Saturday:
                        return RRule.SA;
                    case DayOfWeek.Sunday:
                        return RRule.SU;
                    case DayOfWeek.Thursday:
                        return RRule.TH;
                    case DayOfWeek.Tuesday:
                        return RRule.TU;
                    case DayOfWeek.Wednesday:
                        return RRule.WE;
                }
            });
            event.rrule = {
                freq: Frequency.WEEKLY,
                interval: availability.illumina_repeatoccurence,
                byweekday: days, //['mo', 'fr'],
                dtstart: start.toISOString(),
                until: until.toISOString()
            };
            break;
    }
    return event as IEvent;

}

export { shiftsFormatter, otherSiteShiftFormatter, leaveFormatter, availabilityFormatter }